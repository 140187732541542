<script setup lang="ts">
import type { SliceInterface, VoixCheckboxFieldInterface, VoixLinkFieldInterface, VoixNumberFieldInterface, VoixTextareaFieldInterface, VoixTextFieldInterface } from '@voix/types'
import type { Swiper as SwiperType } from 'swiper'
import { ProseA } from '#build/components'

import { Swiper, SwiperSlide } from 'swiper/vue'
// Import Swiper styles
import 'swiper/css'

const props = defineProps<{
  fields: {
    title: VoixTextFieldInterface
    description: VoixTextareaFieldInterface
    slidesPerView: VoixTextFieldInterface
    spaceBetweenSlides: VoixNumberFieldInterface
    autoPlay: VoixCheckboxFieldInterface
    buttonLink: VoixLinkFieldInterface
    textLink: VoixLinkFieldInterface
  }
  slice: SliceInterface
}>()

defineSlice({
  name: { label: 'Clipped Slider', group: 'Sliders', layouts: ['*'] },
  preview: 'SlicesGlueSlidersClippedSlider.jpg',
  description: 'Slider of Images that has slides that go all the way across!',
  tags: ['Slider'],

  slots: [{ name: 'default' }],
  fields: {
    title: { type: 'text', label: 'Title' },
    description: { type: 'textarea', label: 'Description' },
    slidesPerView: { type: 'text', label: 'Slides Per View', default: 'auto' },
    spaceBetweenSlides: { type: 'number', label: 'Space Between Slides', default: 0 },
    autoPlay: { type: 'checkbox', label: 'Auto Play Slider?', default: false },
    buttonLink: { type: 'link', label: 'Button Link', enabled: false },
    textLink: { type: 'link', label: 'Text Link', enabled: false },
  },
  templates: [{
    label: 'Center Bleed Slider',
    fields: {
      buttonLink: { value: { text: 'view all dining', target: '_self', relationship: null, href: '\/new-page' } },
      description: { value: 'With over 40 restaurants and 3 Michelin starred chefs, you can see why Atlantis is an epicurean destination.' },
      isRight: { value: true },
      linkPosition: { value: 'underneath' },
      title: { value: 'ATLANTIS BAHAMAS - THE CULINARY CAPITAL OF THE CARIBBEAN' },
    },
  }],
})

const swiper: Ref<SwiperType | null> = ref(null)
const windowWidth = ref(1200)

const activeIndex = computed(() => {
  if (swiper.value)
    return swiper.value.realIndex

  return null
})
const autoplayOptions = computed(() => {
  if (props.fields.autoPlay.value) {
    return {
      delay: 5000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }
  }

  return false
})

const slides = computed(() => {
  // because of the style of the slider
  // it needs to always have at least 4 slides to properly loop

  const slides = props.slice.elements

  if (props.slice.elements.length === 1) {
    // if we have 1 slice we need to add 3
    slides.push(props.slice.elements[0])
    slides.push(props.slice.elements[0])
    slides.push(props.slice.elements[0])
  }
  else if (props.slice.elements.length === 2) {
    // if we have 2 slices we need to double it
    slides.push(props.slice.elements[0])
    slides.push(props.slice.elements[1])
  }
  else if (props.slice.elements.length === 3) {
    // if we have 3 slices we need to double it
    slides.push(props.slice.elements[0])
    slides.push(props.slice.elements[1])
    slides.push(props.slice.elements[2])
  }

  return slides
})

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}

function goToSlide(index: number) {
  if (swiper.value)
    swiper.value.slideTo(index)
}

onMounted(() => {
  windowWidth.value = window.innerWidth

  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth
  })
})
</script>

<template>
  <div class="py-4 md:py-24">
    <div
      class="container mx-auto text-center lg:mb-12 flex flex-col items-center px-8"
      :class="{
        'mb-12': fields.buttonLink.enabled || fields.textLink.enabled,
      }"
    >
      <div
        v-if="fields.title.value"
        class="headline mb-3 max-w-4xl"
      >
        {{ fields.title.value }}
      </div>
      <div
        v-if="fields.description.value"
        class="max-w-4xl body-lg mb-6"
      >
        {{ fields.description.value }}
      </div>

      <a
        v-if="fields.buttonLink.enabled"
        :href="fields.buttonLink.value?.href"
        :target="fields.buttonLink.value.target"
        :rel="fields.buttonLink.value.rel"
        class="btn btn-ghost btn-xs tracking-widest"
      >
        {{ fields.buttonLink.value.text }}</a>
      <a
        v-if="fields.textLink.enabled"
        :href="fields.textLink.value?.href"
        :target="fields.textLink.value.target"
        :rel="fields.textLink.value.rel"
        class="special-learnmore -mb-1 uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border"
      >
        {{ fields.textLink.value.text }}
      </a>
    </div>

    <div class="relative">
      <Swiper
        v-if="slides.length > 0"
        slides-per-view="auto"
        :space-between="0"
        :loop="true"
        :autoplay="autoplayOptions"
        :centered-slides="true"
        :breakpoints="{
          640: {
            slidesPerView: 'auto',
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 'auto',
            spaceBetween: 40,
          },
          1360: {
            slidesPerView: fields.slidesPerView.value,
            spaceBetween: fields.spaceBetweenSlides.value,
          } }"
        @swiper="onSwiper"
      >
        <SwiperSlide
          v-for="(s, key) in slides"
          :key="key"
          class="lg:mx-0 flex justify-center max-w-full "
          style="direction: ltr"
        >
          <div
            class="h-full flex duration-200" :class="{
              'scale-75': key !== activeIndex % slides.length,
              'scale-90 lg:scale-100': key === activeIndex % slides.length,
            }"
          >
            <VoixSlice
              :slice="s"
              :slice-index="key"
              style="direction: ltr"
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <div
        v-if="slides.length > 1"
        class="absolute z-50 inset-0 pointer-events-none flex justify-between items-center px-8"
      >
        <button class="slider-button border-gray-500" @click="prev">
          <svg
            class="w-7 h-7"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button class="slider-button border-gray-500" @click="next">
          <svg
            class="w-7 h-7 transform rotate-180"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="flex justify-center px-4 mt-4">
      <div class="flex space-x-3">
        <button
          v-for="(slide, n) in slides"
          :key="n"
          class="h-1.5 w-1.5 rounded-full transistion-colors duration-500"
          :class="{
            'bg-gray-500': n !== activeIndex % slides.length,
            'bg-white': n === activeIndex % slides.length,
          }"
          @click="goToSlide(n)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  @apply flex;

  width: auto;
  height: auto;

}
.swiper-wrapper {
  @apply items-stretch;
}
</style>
